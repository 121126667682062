import React, { Suspense, Component } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles/theme";
import LoadingComponent from "./components/LoadingComponent";
import history from "./services/history";
import { Redirect } from "react-router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";

class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale="id">
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Router history={history}>
              <Suspense fallback={<LoadingComponent />}>
                <Switch>
                  <Route
                    path="/login"
                    component={React.lazy(() => import("./pages/UnAuth/Login"))}
                  />
                  <Route
                    path="/register"
                    component={React.lazy(() =>
                      import("./pages/UnAuth/Register")
                    )}
                  />
                  <Route
                    exact
                    path="/"
                    component={React.lazy(() => import("./pages/Home"))}
                  />
                  <Route
                    path="/event"
                    component={React.lazy(() => import("./pages/EventBanner"))}
                  />
                  <Route
                    exact
                    path="/articles"
                    component={React.lazy(() => import("./pages/Article"))}
                  />
                  <Route
                    path="/articles/detail"
                    component={React.lazy(() =>
                      import("./pages/Article/Detail")
                    )}
                  />
                  <Route
                    path="/medical-history"
                    component={React.lazy(() =>
                      import("./pages/MedicalHistory")
                    )}
                  />
                  <Route
                    exact
                    path="/booking-history"
                    component={React.lazy(() =>
                      import("./pages/BookingHistory")
                    )}
                  />
                  <Route
                    path="/booking-history/form"
                    component={React.lazy(() =>
                      import("./pages/BookingHistory/Form")
                    )}
                  />
                  <Route
                    exact
                    path="/profile"
                    component={React.lazy(() => import("./pages/Profile"))}
                  />
                  <Route
                    path="/profile/change_password"
                    component={React.lazy(() =>
                      import("./pages/Profile/ChangePassword")
                    )}
                  />
                  <Route
                    path="/error"
                    component={React.lazy(() => import("./pages/Error"))}
                  />
                  <Redirect to="/error" />
                </Switch>
              </Suspense>
            </Router>
          </Provider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
