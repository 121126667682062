export const FETCH_EXAMPLE = "FETCH_EXAMPLE";

export const FETCH_MERCHANT = "FETCH_MERCHANT";
export const FETCH_MERCHANT_ID = "FETCH_MERCHANT_ID";

export const FETCH_OUTLET = "FETCH_OUTLET";
export const FETCH_OUTLET_ID = "FETCH_OUTLET_ID";

export const GET_ITEM = "GET_ITEM";
export const GET_CART = "GET_CART";
export const SET_CART = "SET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const SUB_QUANTITY = "SUB_QUANTITY";
export const CHANGE_NOTE_ITEM = "CHANGE_NOTE_ITEM";
export const ADD_QUANTITY_ITEM = "ADD_QUANTITY_ITEM";
export const SUB_QUANTITY_ITEM = "SUB_QUANTITY_ITEM";
