import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { defaultBgColor, primaryColor } from "../constant/theme";

export const theme = createMuiTheme({
  shadows: ["none"],
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: grey[50],
    },
  },
});

export const customSelectStyles = {
  menu: (styles) => ({ ...styles, zIndex: 999 }),
  option: (styles) => ({
    ...styles,
    background: "white",
    borderBottom: "1px",
    color: "black",
    padding: 5,
  }),
};
